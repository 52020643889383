/*
Template Name: SimpleAdmin Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Main file css
*/
@import url("https://fonts.googleapis.com/css?family=Hind:400,500,700|Noto+Sans:400,700");
/*=====================
         Menu
  =====================*/
.topbar .topbar-left {
  float: left;
  position: relative;
  width: 190px;
  z-index: 999; }

.logo {
  color: #626773 !important;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 70px;
  text-transform: uppercase; }
  .logo img {
    height: 50px; }
  .logo h1 {
    height: 50px;
    margin: 0 auto;
    text-align: center; }
  .logo span {
    color: #23b195; }

.navbar-default {
  background-color: #f5f5f5;
  border-radius: 0;
  z-index: 99;
  border: none;
  margin-bottom: 0; }
  .navbar-default .navbar-nav > .open > a {
    background-color: rgba(122, 125, 132, 0.1); }
    .navbar-default .navbar-nav > .open > a:focus {
      background-color: rgba(122, 125, 132, 0.1); }
    .navbar-default .navbar-nav > .open > a:hover {
      background-color: rgba(122, 125, 132, 0.1); }
  .navbar-default .label {
    position: absolute;
    top: 12px;
    right: 7px; }

.navbar-nav {
  margin: 0; }

.navbar-default .navbar-nav.top-navbar-items > li > a {
  line-height: 70px;
  padding: 0 15px; }

.top-navbar-items-right {
  margin-right: -15px; }
  .top-navbar-items-right a.menu-right-item {
    padding: 0 15px !important; }
    .top-navbar-items-right a.menu-right-item i {
      line-height: 70px;
      font-size: 26px; }

.button-menu-mobile {
  background: transparent;
  border: none;
  color: #626773;
  font-size: 21px;
  line-height: 70px;
  padding: 0 15px;
  outline: none !important; }

.profile {
  line-height: 70px !important; }
  .profile img {
    height: 36px;
    width: 36px; }

.dropdown-menu-lg {
  width: 300px !important; }
  .dropdown-menu-lg .list-group {
    margin-bottom: 0; }
  .dropdown-menu-lg .list-group-item {
    border: none;
    border-bottom: 2px solid #f5f5f5;
    padding: 14px 20px;
    border-radius: 0 !important; }
  .dropdown-menu-lg .media-heading {
    margin-bottom: 0; }
  .dropdown-menu-lg .media-body p {
    color: #7a7d84;
    line-height: 16px; }

.notification-list em {
  width: 36px;
  color: #ffffff;
  text-align: center;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  margin-top: 2px;
  font-size: 16px; }
.notification-list .media-left {
  float: left;
  display: inherit; }
.notification-list .media-body {
  display: inherit;
  width: auto;
  overflow: hidden;
  margin-left: 50px; }
  .notification-list .media-body h5 {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%;
    overflow: hidden;
    line-height: 22px;
    font-size: 13px;
    font-weight: 700; }

.notifi-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 11px 20px 15px;
  color: #626773; }

.app-search {
  position: relative;
  margin: 18px 15px 15px 5px; }
  .app-search a {
    position: absolute;
    top: 8px;
    right: 16px;
    color: #7a7d84; }
  .app-search .form-control,
  .app-search .form-control:focus {
    font-size: 13px;
    color: #626773;
    padding-left: 20px;
    padding-right: 40px;
    background: transparent;
    border: 1px solid rgba(122, 125, 132, 0.15);
    box-shadow: none;
    border-radius: 30px;
    height: 36px;
    font-weight: 600;
    width: 180px; }

.scrollbar-wrapper {
  height: 100%;
  position: relative; }

.sidebar-navigation {
  position: absolute;
  background-color: #ffffff;
  width: 240px;
  top: 80px;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
  background-repeat: repeat;
  z-index: 99; }

.user-details {
  min-height: 80px;
  padding: 20px;
  position: relative; }
  .user-details img {
    position: relative;
    z-index: 9999;
    height: 48px;
    width: 48px; }
  .user-details .user-info {
    color: #626773;
    margin-left: 60px;
    position: relative;
    z-index: 99999; }
    .user-details .user-info p {
      margin-bottom: 0;
      font-size: 13px; }
    .user-details .user-info a {
      color: #626773;
      display: block;
      font-weight: 600;
      padding-top: 5px; }

.metisMenu {
  padding-bottom: 30px;
  padding-top: 10px; }
  .metisMenu .arrow {
    float: right;
    line-height: 16px;
    padding-top: 2px;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out; }
  .metisMenu .fa.arrow:before {
    content: "\f105"; }
  .metisMenu .active > a > .fa.arrow:before {
    content: "\f107"; }

.metisMenu.nav > li > a {
  padding: 13px 20px;
  color: #626773; }
  .metisMenu.nav > li > a .label {
    margin-top: 2px; }
  .metisMenu.nav > li > a:hover, .metisMenu.nav > li > a:focus {
   color: #ffffff;
    background: #ee5827; }
  .metisMenu.nav > li > a i {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -4px;
    width: 20px;
    font-size: 16px; }

.nav-second-level.nav li a, .nav-thrid-level.nav li a {
  padding: 7px 20px 7px 10px;
  text-transform: capitalize;
  color: #888d9a; }

.nav-second-level.nav li a:hover, .nav-second-level.nav li a:focus, .nav-thrid-level.nav li a:hover, .nav-thrid-level.nav li a:focus {
  color: #626773;
  background: transparent; }

.nav-second-level.nav > li > a {
  padding-left: 48px; }

.nav-second-level.nav li.active a {
  color: #4b4e57; }

.nav-third-level.nav > li > a {
  padding-left: 58px; }

.nav-third-level.nav li.active a {
  color: #4b4e57; }

.metisMenu.nav > li > a.active {
  background: #ee5827;
  color: #ffffff;
}

.metisMenu.nav > li.active > a li.active a {
  color: #23b195; }

.metisMenu.nav > li:last-child a {
  border-bottom: 0; }

#page-right-content {
  background-color: #ffffff;
  border-left: 6px solid #f5f5f5;
  padding: 90px 5px 50px 5px;
  margin-top: -70px;
  margin-left: 240px;
  min-height: 1050px;
  position: relative !important;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s; }

.footer {
  border-top: 2px solid #f5f5f5;
  bottom: 0;
  left: 0;
  padding: 14px 20px;
  position: absolute;
  right: 0; }

@media (max-width: 420px) {
  .dropdown-menu-lg {
    width: 240px !important; } }
@media (max-width: 991px) {
  .logo-lg {
    display: none; }

  .logo-sm {
    display: inline-block !important; }

  .top-menu-item-xs {
    display: inline-flex !important; }

  .topbar .topbar-left {
    width: 70px; }

  .navbar-nav .open .dropdown-menu {
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    left: auto;
    position: absolute;
    right: 0; }

  .footer {
    text-align: center; } }
@media (max-width: 991px) {
  .sidebar-navigation {
    margin-left: -2200px;
    z-index: 3; }

  body.nav-collapse .sidebar-navigation {
    margin-left: 0;
    box-shadow: 7px 8px 9px rgba(0, 0, 0, 0.11);
    top: 0;
    z-index: 999;
    position: fixed;
    bottom: 0; }

  .scrollbar-wrapper {
    overflow: auto; }

  #page-right-content {
    margin-left: 0; }

  body.nav-collapse #page-right-content {
    margin-left: 0; } }
@media (max-width: 1024px) {
  #page-wrapper {
    width: 100% !important; }

  .topbar .topbar-left {
    padding-left: 15px; 
    padding-top: 10px;} }
.btn-mobile-view {
  height: 30px;
  width: 30px;
  line-height: 32px;
  background-color: #23b195;
  padding: 0;
  border-radius: 3px;
  float: right;
  z-index: 9999;
  position: relative;
  top: 10px;
  color: #ffffff; }

table {
    font-size: 14px !important;
    font-family: "Noto Sans", sans-serif;
    color: #676a6c;
}

body {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  color: #676a6c; }

#page-wrapper {
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff; }

/* =============
   Bootstrap-custom
============= */
.row {
  margin-right: -10px;
  margin-left: -10px; }

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4,
.col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10,
.col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
.col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8,
.col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3,
.col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  padding-left: 10px;
  padding-right: 10px; }

.container {
  width: auto; }

blockquote {
  background-color: #f9f9f9; }

a {
  text-decoration: none !important; }

button {
  outline: none !important; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; }

.dropdown-menu {
  padding: 4px 0;
  transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(152, 166, 173, 0.15); }

.dropdown-menu > li > a {
  padding: 6px 20px; }

.card-box {
  background-color: #ffffff;
  border: 2px solid #f5f5f5;
  padding: 20px;
  margin-bottom: 20px; }

.header-title {
  margin-top: 0;
  font-weight: bold;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 12px; }

.font-normal {
  font-weight: normal; }

.line-h-24 {
  line-height: 24px; }

.m-0 {
  margin: 0 !important; }

.m-t-0 {
  margin-top: 0 !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-b-0 {
  margin-bottom: 0 !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-0 {
  padding: 0 !important; }

.p-20 {
  padding: 20px; }

.p-b-0 {
  padding-bottom: 0 !important; }

.p-l-0 {
  padding-left: 0 !important; }

.p-r-0 {
  padding-right: 0 !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.font-13 {
  font-size: 13px; }

.thumb-sm {
  height: 32px;
  width: 32px; }

.thumb-xl {
  height: 120px;
  width: 120px; }

.center-page {
  float: none !important;
  margin: 0 auto; }

/* Text colors */
.text-custom {
  color: #23b195; }

.text-white {
  color: #ffffff; }

.text-danger {
  color: #d57171; }

.text-muted {
  color: #7a7d84; }

.text-primary {
  color: #458bc4; }

.text-warning {
  color: #e2ab3b; }

.text-success {
  color: #4fc55b; }

.text-info {
  color: #3db9dc; }

.text-dark {
  color: #626773 !important; }

/* Background colors */
.bg-custom {
  background-color: #23b195 !important; }

.bg-primary {
  background-color: #458bc4 !important; }

.bg-success {
  background-color: #4fc55b !important; }

.bg-info {
  background-color: #3db9dc !important; }

.bg-warning {
  background-color: #e2ab3b !important; }

.bg-danger {
  background-color: #d57171 !important; }

.bg-muted {
  background-color: #7a7d84 !important; }

.bg-white {
  background-color: #ffffff !important; }

/* Labels */
.label {
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: .3em .6em .3em; }

.label-white {
  background-color: rgba(255, 255, 255, 0.7);
  color: #626773 !important; }

.label-custom {
  background-color: #23b195; }

.label-primary {
  background-color: #458bc4; }

.label-success {
  background-color: #4fc55b; }

.label-info {
  background-color: #3db9dc; }

.label-warning {
  background-color: #e2ab3b; }

.label-danger {
  background-color: #d57171; }

.label-dark {
  background-color: #626773; }

/* Badge */
.badge {
  text-transform: uppercase;
  font-weight: 600;
  padding: 3px 5px;
  font-size: 12px;
  margin-top: 1px;
  background-color: #23b195; }

.badge-xs {
  font-size: 9px; }

.badge-xs, .badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.badge-white {
  background-color: rgba(255, 255, 255, 0.7);
  color: #626773 !important; }

.badge-primary {
  background-color: #458bc4; }

.badge-success {
  background-color: #4fc55b; }

.badge-info {
  background-color: #3db9dc; }

.badge-warning {
  background-color: #e2ab3b; }

.badge-danger {
  background-color: #d57171; }

.badge-dark {
  background-color: #626773; }

/* Pagination/ Pager */
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.pagination > li > a,
.pagination > li > span {
  color: #626773; }

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #f5f5f5; }

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left; }

.pagination-split li:first-child {
  margin-left: 0; }

.pagination-split li a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #23b195;
  border-color: #23b195; }

.pager li > a, .pager li > span {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #626773; }

.list-group-item.active, .list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #23b195;
  border-color: #23b195; }

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: rgba(255, 255, 255, 0.7); }

/* =================
   Popover / Tooltips
==================== */
/* Popover */
.popover {
  font-family: inherit;
  border: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.15); }
  .popover .popover-title {
    background-color: transparent;
    color: #23b195;
    padding: 12px 15px;
    font-size: 15px; }
  .popover .arrow {
    border-color: transparent !important; }

/* Tooltips */
.tooltip .tooltip-inner {
  padding: 4px 10px;
  border-radius: 2px;
  background-color: #464952; }

.tooltip.left .tooltip-arrow {
  border-left-color: #464952; }

.tooltip.top .tooltip-arrow {
  border-top-color: #464952; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #464952; }

.tooltip.right .tooltip-arrow {
  border-right-color: #464952; }

.button-list {
  margin-left: -8px;
  margin-bottom: -12px; }
  .button-list .btn {
    margin-bottom: 12px;
    margin-left: 8px; }

/* Demo only */
.icon-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden; }
  .icon-list-demo div p {
    margin-bottom: 0;
    line-height: inherit; }
.icon-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  border: 1px solid #e8e8e8;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  color: rgba(43, 61, 81, 0.7);
  border-radius: 3px;
  display: inline-block;
  transition: all 0.2s; }
.icon-list-demo .col-md-4 {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px; }
  .icon-list-demo .col-md-4:hover i {
    color: #23b195; }

.switchery-demo .switchery {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px; }

/* =============
   Buttons
============= */
.btn {
  border-radius: 2px;
  padding: 6px 14px;
  outline: none !important; }

.btn-md {
  padding: 8px 18px; }

.btn-group-lg > .btn, .btn-lg {
  padding: 10px 16px !important;
  font-size: 16px; }

.btn-group-sm > .btn, .btn-sm {
  padding: 5px 10px !important; }

.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px !important; }

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset; }

.btn-custom, .btn-primary, .btn-success, .btn-info, .btn-warning,
.btn-danger, .btn-dark, .btn-purple, .btn-pink, .btn-orange,
.btn-brown, .btn-teal {
  color: #ffffff !important; }

.btn-custom {
  background-color: #23b195;
  border-color: #23b195; }

.btn-custom:hover, .btn-custom:focus, .btn-custom:active, .btn-custom.active,
.btn-custom.focus, .btn-custom:active, .btn-custom:focus, .btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  background-color: #1f9c83 !important;
  border: 1px solid #1f9c83 !important; }

.btn-default {
  background-color: #ffffff;
  border-color: #cccccc; }

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active,
.btn-default.focus, .btn-default:active, .btn-default:focus, .btn-default:hover,
.open > .dropdown-toggle.btn-default {
  background-color: #ededed !important;
  border: 1px solid #cccccc !important; }

.btn-primary {
  background-color: #458bc4 !important;
  border: 1px solid #458bc4 !important; }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
.btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #3a7eb6 !important;
  border: 1px solid #3a7eb6 !important; }

.btn-success {
  background-color: #4fc55b !important;
  border: 1px solid #4fc55b !important; }

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover, .open > .dropdown-toggle.btn-success {
  background-color: #3ebc4b !important;
  border: 1px solid #3ebc4b !important; }

.btn-info {
  background-color: #3db9dc !important;
  border: 1px solid #3db9dc !important; }

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover, .open > .dropdown-toggle.btn-info {
  background-color: #27b1d8 !important;
  border: 1px solid #27b1d8 !important; }

.btn-warning {
  background-color: #e2ab3b !important;
  border: 1px solid #e2ab3b !important; }

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover, .open > .dropdown-toggle.btn-warning {
  background-color: #dfa125 !important;
  border: 1px solid #dfa125 !important; }

.btn-danger {
  background-color: #d57171 !important;
  border: 1px solid #d57171 !important; }

.btn-danger:active, .btn-danger:focus, .btn-danger:hover, .btn-danger.active, .btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover, .open > .dropdown-toggle.btn-danger {
  background-color: #cf5d5d !important;
  border: 1px solid #cf5d5d !important; }

.btn-dark {
  background-color: #626773 !important;
  border: 1px solid #626773 !important; }

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus, .btn-dark:active, .btn-dark:focus, .btn-dark:hover, .open > .dropdown-toggle.btn-dark {
  background-color: #565b65 !important;
  border: 1px solid #565b65 !important; }

.btn-bordered {
  border-bottom: 3px solid transparent; }

.btn-bordered.btn-default {
  background-color: #ffffff;
  border-bottom: 2px solid #d9d9d9 !important; }

.btn-bordered.btn-custom {
  background-color: #23b195;
  border-bottom: 2px solid #1d937c !important; }

.btn-bordered.btn-primary {
  border-bottom: 2px solid #3779ae !important; }

.btn-bordered.btn-success {
  border-bottom: 2px solid #3cb548 !important; }

.btn-bordered.btn-info {
  border-bottom: 2px solid #25aad0 !important; }

.btn-bordered.btn-warning {
  border-bottom: 2px solid #cc931e !important; }

.btn-bordered.btn-danger {
  border-bottom: 2px solid #c94a4a !important; }

.btn-bordered.btn-dark {
  border-bottom: 2px solid #33363c !important; }

.btn-rounded {
  border-radius: 2em;
  padding: 6px 18px; }

/* ===========
   Panels
 =============*/
.panel {
  border: 2px solid #f5f5f5;
  box-shadow: none;
  margin-bottom: 20px; }
  .panel .panel-body {
    padding: 20px; }
    .panel .panel-body p {
      margin-bottom: 0;
      line-height: 24px; }
    .panel .panel-body p + p {
      padding-top: 10px; }

.panel-heading {
  border: none !important;
  padding: 15px 20px;
  margin: -2px;
  border-radius: 4px 4px 0 0; }

.panel-default > .panel-heading {
  background-color: #f5f5f5;
  border-bottom: none;
  color: #7a7d84; }

.panel-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0; }

.panel-sub-title {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.7) !important;
  margin-top: 3px; }

.panel-footer {
  background: #f5f5f5;
  border-top: 0; }

.panel-default .panel-sub-title {
  color: inherit !important; }

.panel-color .panel-title {
  color: #ffffff; }

.panel-custom > .panel-heading {
  background-color: #23b195; }

.panel-primary > .panel-heading {
  background-color: #458bc4; }

.panel-success > .panel-heading {
  background-color: #4fc55b; }

.panel-info > .panel-heading {
  background-color: #3db9dc; }

.panel-warning > .panel-heading {
  background-color: #e2ab3b; }

.panel-danger > .panel-heading {
  background-color: #d57171; }

.panel-dark > .panel-heading {
  background-color: #626773; }

/* =============
   Tabs
============= */
.tab-content {
  padding: 20px 0 0 0; }

.nav-tabs > li > a {
  color: #626773;
  text-transform: uppercase;
  font-weight: 600; }
  .nav-tabs > li > a:hover {
    background-color: #f2f2f2; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  color: #23b195; }

.tabs-bordered {
  border-bottom: 2px solid #cccccc !important; }

.tabs-bordered li a, .tabs-bordered li a:hover, .tabs-bordered li a:focus {
  border: 0 !important;
  background-color: #ffffff !important;
  padding: 10px 20px !important; }

.tabs-bordered li.active a, .tabs-bordered li.active a:hover, .tabs-bordered li.active a:focus {
  border-bottom: 2px solid #23b195 !important;
  margin-bottom: -1px;
  color: #23b195; }

/* Navpills */
.nav-pills > li > a {
  color: #626773; }

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: #23b195; }

/* Modals */
.modal .modal-dialog .modal-content {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #DDDDDD;
  border-radius: 2px;
  box-shadow: none;
  padding: 25px; }
  .modal .modal-dialog .modal-content .modal-header {
    border-bottom-width: 2px;
    margin: 0;
    padding: 0;
    padding-bottom: 15px; }
  .modal .modal-dialog .modal-content .modal-body {
    padding: 20px 0; }
  .modal .modal-dialog .modal-content .modal-footer {
    padding: 0;
    padding-top: 15px; }

.modal-full {
  width: 98%; }

.modal-content .nav.nav-tabs + .tab-content {
  margin-bottom: 0px; }
.modal-content .panel-group {
  margin-bottom: 0px; }
.modal-content .panel {
  border-top: none; }

/* =============
   Progressbars
============= */
.progress {
  -webkit-box-shadow: none !important;
  background-color: #f5f5f5;
  box-shadow: none !important;
  height: 10px;
  margin-bottom: 18px;
  overflow: hidden; }

.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px; }

.progress.progress-sm {
  height: 5px !important; }
  .progress.progress-sm .progress-bar {
    font-size: 8px;
    line-height: 5px; }

.progress.progress-md {
  height: 15px !important; }
  .progress.progress-md .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px; }

.progress.progress-lg {
  height: 20px !important; }
  .progress.progress-lg .progress-bar {
    font-size: 12px;
    line-height: 20px; }

.progress-bar-primary {
  background-color: #458bc4; }

.progress-bar-success {
  background-color: #4fc55b; }

.progress-bar-info {
  background-color: #3db9dc; }

.progress-bar-warning {
  background-color: #e2ab3b; }

.progress-bar-danger {
  background-color: #d57171; }

.progress-bar-dark {
  background-color: #626773; }

.progress-bar-custom {
  background-color: #23b195; }

/* =============
   Alerts
============= */
.alert {
  position: relative; }
  .alert .alert-link {
    font-weight: 600; }

.alert-dismissable .close, .alert-dismissible .close {
  opacity: 0.9; }

.alert-icon {
  padding-left: 50px; }
  .alert-icon i {
    position: absolute;
    left: 0;
    height: 50px;
    width: 50px;
    text-align: center;
    top: 0;
    line-height: 50px;
    font-size: 22px; }

.alert-success {
  color: #4fc55b;
  background-color: #e8f8ea;
  border-color: #9cdea2; }
  .alert-success .alert-link {
    color: #38a943; }
  .alert-success hr {
    border-top-color: #38a943; }

.alert-info {
  color: #3db9dc;
  background-color: #eaf7fb;
  border-color: #93d8ec; }
  .alert-info .alert-link {
    color: #23a0c3; }
  .alert-info hr {
    border-top-color: #23a0c3; }

.alert-warning {
  color: #e2ab3b;
  background-color: #fcf7ed;
  border-color: #efd194; }
  .alert-warning .alert-link {
    color: #cc931e; }
  .alert-warning hr {
    border-top-color: #cc931e; }

.alert-danger {
  color: #d57171;
  background-color: #faefef;
  border-color: #ecc0c0; }
  .alert-danger .alert-link {
    color: #c94a4a; }
  .alert-danger hr {
    border-top-color: #c94a4a; }

.alert-white {
  background-color: #ffffff !important; }

.swal2-modal {
  font-family: "Noto Sans", sans-serif; }
  .swal2-modal .swal2-title {
    font-size: 28px; }
  .swal2-modal .swal2-content {
    font-size: 16px; }
  .swal2-modal .swal2-spacer {
    margin: 10px 0; }
  .swal2-modal .swal2-file, .swal2-modal .swal2-input, .swal2-modal .swal2-textarea {
    border: 2px solid #7a7d84;
    font-size: 16px;
    box-shadow: none !important; }

.swal2-icon.swal2-question {
  color: #23b195;
  border-color: #23b195; }

.swal2-icon.swal2-success {
  border-color: #4fc55b; }
  .swal2-icon.swal2-success .line {
    background-color: #4fc55b; }
  .swal2-icon.swal2-success .placeholder {
    border-color: #4fc55b; }

.swal2-icon.swal2-warning {
  color: #e2ab3b;
  border-color: #e2ab3b; }

.swal2-icon.swal2-error {
  border-color: #d57171; }
  .swal2-icon.swal2-error .line {
    background-color: #d57171; }

.swal2-modal .swal2-file:focus, .swal2-modal .swal2-input:focus, .swal2-modal .swal2-textarea:focus {
  outline: 0;
  border: 2px solid #23b195; }

/* =============
   Form
============= */
textarea.form-control {
  min-height: 90px; }

.form-control {
  border: 1px solid gainsboro;
  border-radius: 4px;
  padding: 7px 12px;
  height: 38px;
  max-width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control:focus {
  border: 1px solid #aaaaaa;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-horizontal .form-group {
  margin-left: -10px;
  margin-right: -10px; }

.form-control-feedback {
  line-height: 38px !important; }

.input-group-btn .btn {
  padding: 8px 12px; }

.input-group-btn .btn-sm {
  padding: 5px 10px; }

.input-group-btn .btn-lg {
  padding: 10px 17px; }

.has-success .checkbox, .has-success .checkbox-inline,
.has-success .control-label, .has-success .help-block,
.has-success .radio, .has-success .radio-inline,
.has-success.checkbox label, .has-success.checkbox-inline label,
.has-success.radio label, .has-success.radio-inline label,
.has-success .form-control-feedback {
  color: #4fc55b; }

.has-warning .checkbox, .has-warning .checkbox-inline,
.has-warning .control-label, .has-warning .help-block,
.has-warning .radio, .has-warning .radio-inline,
.has-warning.checkbox label, .has-warning.checkbox-inline label,
.has-warning.radio label, .has-warning.radio-inline label,
.has-warning .form-control-feedback {
  color: #e2ab3b; }

.has-error .checkbox, .has-error .checkbox-inline,
.has-error .control-label, .has-error .help-block,
.has-error .radio, .has-error .radio-inline,
.has-error.checkbox label, .has-error.checkbox-inline label,
.has-error.radio label, .has-error.radio-inline label,
.has-error .form-control-feedback {
  color: #d57171; }

.has-success .form-control {
  border-color: #4fc55b;
  box-shadow: none !important; }

.has-warning .form-control {
  border-color: #e2ab3b;
  box-shadow: none !important; }

.has-error .form-control {
  border-color: #d57171;
  box-shadow: none !important; }

.input-group-addon {
  border-radius: 2px;
  border: 1px solid #eeeeee; }

/* =============
   Form Advanced
============= */
/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 5px 7px 5px;
  width: 100%;
  border: 1px solid #e3e3e3; }
  .bootstrap-tagsinput .label-info {
    background-color: #23b195 !important;
    display: inline-block;
    padding: 5px;
    margin: 3px 1px; }

/* File style */
.icon-span-filestyle {
  padding-right: 5px; }

/* Total BizXpert */
.t-a-r-total-bizx {
    text-align: right;  
}

/* Select BizXpert */
.selectBizx {
    height: 30px;  
    padding: 6px 12px;
}

/* Select 2 */
.select2-container .select2-selection--single {
  border: 1px solid #E3E3E3 !important;
  height: 38px !important; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 36px !important;
    padding-left: 12px !important; }
  .select2-container .select2-selection--single .select2-selection__arrow {
    height: 34px;
    width: 34px;
    right: 3px; }
    .select2-container .select2-selection--single .select2-selection__arrow b {
      border-color: #999 transparent transparent transparent;
      border-width: 6px 6px 0 6px; }

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #999 transparent !important;
  border-width: 0 6px 6px 6px !important; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #23b195; }

.select2-results__option {
  padding: 6px 12px; }

.select2-dropdown {
  border: 1px solid #e3e3e3 !important;
  padding-top: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15); }

.select2-search input {
  border: 1px solid #e3e3e3 !important; }

.select2-container .select2-selection--multiple {
  min-height: 38px !important;
  border: 1px solid #e3e3e3 !important; }
  .select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 2px 10px; }
  .select2-container .select2-selection--multiple .select2-search__field {
    margin-top: 7px;
    border: 0 !important; }
  .select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 1px;
    padding: 0 7px; }

/* Form validation */
.parsley-error {
  border-color: #d57171 !important; }

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0; }

.parsley-errors-list.filled {
  display: none; }

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #d57171;
  margin-top: 5px; }

.bootstrap-timepicker-widget table td input {
  border: 1px solid rgba(98, 103, 115, 0.3);
  width: 35px; }

.datepicker-dropdown {
  padding: 10px !important; }

.datepicker td, .datepicker th {
  width: 30px;
  height: 30px; }

.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active, .datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled], .datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected, .datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover {
  background-color: #23b195 !important;
  color: #ffffff !important;
  background-image: none !important;
  text-shadow: none !important; }

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: #4fc55b !important;
  color: #ffffff !important;
  background-image: none !important; }

.datepicker-inline {
  border: 2px solid rgba(98, 103, 115, 0.1); }

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #23b195; }

.daterangepicker .input-mini.active {
  border: 1px solid rgba(98, 103, 115, 0.3); }

.daterangepicker .ranges li {
  border-radius: 2px;
  color: #626773;
  font-weight: 600;
  font-size: 12px;
  font-family: "Hind", sans-serif; }

.daterangepicker select.hourselect, .daterangepicker select.minuteselect,
.daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  border: 1px solid rgba(98, 103, 115, 0.3);
  padding: 2px;
  width: 60px; }

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background-color: #23b195;
  border: 1px solid #23b195;
  color: #ffffff; }

/* Summernote */
.note-editor {
  position: relative; }
  .note-editor .btn-default {
    background-color: transparent;
    border-color: transparent !important; }
  .note-editor .btn-group-sm > .btn, .note-editor .btn-sm {
    padding: 8px 12px !important; }
  .note-editor .note-toolbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    margin: 0; }
  .note-editor .note-statusbar {
    background-color: #ffffff; }
    .note-editor .note-statusbar .note-resizebar {
      border-top: none;
      height: 15px;
      padding-top: 3px; }

.note-editor.note-frame {
  border: 1px solid #f5f5f5 !important; }

.note-popover .popover .popover-content {
  padding: 5px 0 10px 5px; }
.note-popover .btn-default {
  background-color: transparent;
  border-color: transparent !important; }
.note-popover .btn-group-sm > .btn, .note-popover .btn-sm {
  padding: 8px 12px !important; }

.note-toolbar {
  padding: 5px 0 10px 5px; }

/* =============
   Checkbox and Radios
============= */
.checkbox {
  padding-left: 20px; 
  margin-left: 10px; }
  .checkbox label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal; 
    width: 250px; }
    .checkbox label::before {
      -o-transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      background-color: #ffffff;
      border-radius: 2px;
      border: 1px solid gainsboro;
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -20px;
      position: absolute;
      transition: 0.3s ease-in-out;
      width: 17px;
      outline: none !important;
      margin-top: 2px; }
    .checkbox label::after {
      color: #7a7d84;
      display: inline-block;
      font-size: 11px;
      height: 16px;
      left: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      position: absolute;
      top: 2px;
      width: 16px; }
  .checkbox input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important; }
    .checkbox input[type="checkbox"]:disabled + label {
      opacity: 0.65; }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline-offset: -2px;
    outline: none; }
  .checkbox input[type="checkbox"]:checked + label::after {
    content: "\F12C";
    font-family: 'Material Design Icons';
    font-weight: bold; }
  .checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #f5f5f5;
    cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox.checkbox-single label {
  height: 17px; }

.checkbox-custom input[type="checkbox"]:checked + label::before {
  background-color: #23b195;
  border-color: #23b195; }
.checkbox-custom input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #458bc4;
  border-color: #458bc4; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d57171;
  border-color: #d57171; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #3db9dc;
  border-color: #3db9dc; }
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #e2ab3b;
  border-color: #e2ab3b; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #4fc55b;
  border-color: #4fc55b; }
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-dark input[type="checkbox"]:checked + label::before {
  background-color: #626773;
  border-color: #626773; }
.checkbox-dark input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

/* Radios */
.radio {
  padding-left: 20px; }
  .radio label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal; }
    .radio label::before {
      -o-transition: border 0.5s ease-in-out;
      -webkit-transition: border 0.5s ease-in-out;
      background-color: #ffffff;
      border-radius: 50%;
      border: 1px solid gainsboro;
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -20px;
      outline: none !important;
      position: absolute;
      transition: border 0.5s ease-in-out;
      width: 17px; }
    .radio label::after {
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: #7a7d84;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 11px;
      left: 3px;
      margin-left: -20px;
      position: absolute;
      top: 3px;
      transform: scale(0, 0);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      width: 11px; }
  .radio input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important; }
    .radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
  .radio input[type="radio"]:focus + label::before {
    outline-offset: -2px; }
  .radio input[type="radio"]:checked + label::after {
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  .radio input[type="radio"]:disabled + label::before {
    cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio.radio-single label {
  height: 17px; }

.radio-custom input[type="radio"] + label::after {
  background-color: #23b195; }
.radio-custom input[type="radio"]:checked + label::before {
  border-color: #23b195; }
.radio-custom input[type="radio"]:checked + label::after {
  background-color: #23b195; }

.radio-primary input[type="radio"] + label::after {
  background-color: #458bc4; }
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #458bc4; }
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #458bc4; }

.radio-danger input[type="radio"] + label::after {
  background-color: #d57171; }
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d57171; }
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d57171; }

.radio-info input[type="radio"] + label::after {
  background-color: #3db9dc; }
.radio-info input[type="radio"]:checked + label::before {
  border-color: #3db9dc; }
.radio-info input[type="radio"]:checked + label::after {
  background-color: #3db9dc; }

.radio-warning input[type="radio"] + label::after {
  background-color: #e2ab3b; }
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #e2ab3b; }
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #e2ab3b; }

.radio-success input[type="radio"] + label::after {
  background-color: #4fc55b; }
.radio-success input[type="radio"]:checked + label::before {
  border-color: #4fc55b; }
.radio-success input[type="radio"]:checked + label::after {
  background-color: #4fc55b; }

.radio-dark input[type="radio"] + label::after {
  background-color: #626773; }
.radio-dark input[type="radio"]:checked + label::before {
  border-color: #626773; }
.radio-dark input[type="radio"]:checked + label::after {
  background-color: #626773; }

/* Data table */
table.dataTable {
  margin-top: 10px !important;
  margin-bottom: 18px !important; }

.table-bordered.dataTable > thead > tr > td, .table-bordered.dataTable > thead > tr > th {
  border-bottom-width: 1px !important; }

/* Fixed Header table */
.fixedHeader-floating {
  top: 0 !important; }

/* Key Table border */
table.dataTable th.focus, table.dataTable td.focus {
  outline: 2px solid #23b195 !important;
  outline-offset: -1px;
  background-color: #23b195;
  color: #ffffff; }

/* Responsive data table */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  box-shadow: 0 0 3px rgba(67, 89, 102, 0.2);
  background-color: #4fc55b; }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #d57171; }

table.dataTable > tbody > tr.child span.dtr-title {
  font-family: "Hind", sans-serif; }

/* ColVid Tables */
div.ColVis {
  float: none;
  margin-right: 30px; }

button.ColVis_Button,
.ColVis_Button:hover {
  float: none;
  border-radius: 3px;
  outline: none !important;
  background: none;
  box-shadow: none;
  color: #ffffff !important;
  background-color: #23b195 !important;
  border: 1px solid #23b195 !important; }

.dataTables_length {
  float: left; }

div.ColVis_collectionBackground {
  background-color: transparent; }

ul.ColVis_collection {
  padding: 10px 0 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: none; }

ul.ColVis_collection li {
  background: transparent !important;
  padding: 3px 10px !important;
  border: none !important;
  box-shadow: none !important; }

#datatable-colvid_info {
  float: left; }

.dt-buttons {
  float: left; }

div#datatable-buttons_info {
  float: left; }

table.dataTable thead th {
  position: relative;
  background-image: none !important; }

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 7px;
  right: 8px;
  display: block;
  font-family: FontAwesome; }

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  padding-top: 0.12em; }

table.dataTable thead th.sorting_asc:after {
  content: "\f0de"; }

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd"; }

.DTFC_LeftBodyWrapper table thead th.sorting:after,
.dataTables_scrollBody table thead th.sorting:after,
.DTFC_RightBodyLiner table thead th.sorting:after,
.DTFC_LeftBodyWrapper table thead th.sorting_asc:after,
.dataTables_scrollBody table thead th.sorting_asc:after,
.DTFC_RightBodyLiner table thead th.sorting_asc:after,
.DTFC_LeftBodyWrapper table thead th.sorting_desc:after,
.dataTables_scrollBody table thead th.sorting_desc:after,
.DTFC_RightBodyLiner table thead th.sorting_desc:after {
  display: none !important; }

/*DataTable email style BizXpert*/
td.user--details.user--details-email {
    font-weight: bold;
}

td.user--details.user--details-email:hover {
    cursor: pointer;
    text-decoration: underline;
}

.embed-responsive {
    height: 450px;
}

/* =============
   Buttons
============= */
/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #626773;
  z-index: 100;
  color: #ffffff;
  opacity: 0.9;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.legend tr {
  height: 20px;
  font-family: "Hind", sans-serif; }

.legendLabel {
  padding-left: 5px !important;
  line-height: 10px;
  padding-right: 10px; }

/* Morris chart */
.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  background-color: #626773;
  border-color: #565b65; }
  .morris-hover.morris-default-style .morris-hover-point, .morris-hover.morris-default-style .morris-hover-row-label {
    color: #ffffff !important; }

.chart-detail-list li {
  margin: 0px 10px; }

/* =============
   Maps
============= */
.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #f5f5f5;
  border-radius: 3px; }

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #23b195;
  border-radius: 4px;
  padding: 10px 20px; }

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute; }

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #23b195; }

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #23b195; }

/* Vector Map */
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px; }

.jvectormap-zoomout {
  top: 40px; }

/* =============
   Timeline
============= */
.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%; }
  .timeline .time-show {
    margin-bottom: 30px;
    margin-right: -75px;
    margin-top: 30px;
    position: relative; }
    .timeline .time-show a {
      color: #ffffff; }
  .timeline:before {
    background-color: rgba(122, 125, 132, 0.3);
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0; }
  .timeline .btn {
    min-width: 150px; }
  .timeline .timeline-icon {
    -webkit-border-radius: 50%;
    background: #7a7d84;
    border-radius: 50%;
    color: #ffffff;
    display: block;
    height: 20px;
    left: -54px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px; }
    .timeline .timeline-icon i {
      color: #ffffff;
      font-size: 13px;
      margin-top: 4px;
      position: absolute;
      left: 4px; }
  .timeline .time-icon:before {
    font-size: 16px;
    margin-top: 5px; }

h3.timeline-title {
  color: #7a7d84;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase; }

.timeline-item {
  display: table-row; }
  .timeline-item:before {
    content: "";
    display: block;
    width: 50%; }
  .timeline-item .timeline-desk .arrow {
    border-bottom: 12px solid transparent;
    border-right: 12px solid #f2f2f2 !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: -12px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 0; }
  .timeline-item .timeline-desk .timeline-box {
    padding: 20px; }
  .timeline-item .timeline-date {
    margin-bottom: 10px; }

.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%; }
.timeline-item.alt .timeline-desk .arrow-alt {
  border-bottom: 12px solid transparent;
  border-left: 12px solid #f2f2f2 !important;
  border-top: 12px solid transparent;
  display: block;
  height: 0;
  left: auto;
  margin-top: -12px;
  position: absolute;
  right: -12px;
  top: 50%;
  width: 0; }
.timeline-item.alt .timeline-desk .album {
  float: right;
  margin-top: 20px; }
  .timeline-item.alt .timeline-desk .album a {
    float: right;
    margin-left: 5px; }
.timeline-item.alt .timeline-icon {
  left: auto;
  right: -56px; }
.timeline-item.alt:before {
  display: none; }
.timeline-item.alt .panel {
  margin-left: 0;
  margin-right: 45px; }
.timeline-item.alt h4 {
  text-align: right; }
.timeline-item.alt p {
  text-align: right; }
.timeline-item.alt .timeline-date {
  text-align: right; }

.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%; }
  .timeline-desk h4 {
    font-size: 16px;
    font-weight: 300;
    margin: 0; }
  .timeline-desk .panel {
    background: #f2f2f2;
    display: block;
    margin-bottom: 5px;
    margin-left: 45px;
    position: relative;
    text-align: left;
    border: 0; }
  .timeline-desk h5 span {
    color: #7a7d84;
    display: block;
    font-size: 12px;
    margin-bottom: 4px; }
  .timeline-desk p {
    color: #999999;
    font-size: 14px;
    margin-bottom: 0; }
  .timeline-desk .album {
    margin-top: 12px; }
    .timeline-desk .album a {
      float: left;
      margin-right: 5px; }
    .timeline-desk .album img {
      height: 36px;
      width: auto;
      border-radius: 3px; }
  .timeline-desk .notification {
    background: none repeat scroll 0 0 #ffffff;
    margin-top: 20px;
    padding: 8px; }

.timeline-left {
  margin-left: 20px;
  width: auto;
  display: block; }
  .timeline-left:before {
    left: 0 !important; }
  .timeline-left .timeline-item {
    display: block; }
  .timeline-left .timeline-desk {
    display: block;
    width: 100%; }
  .timeline-left .panel {
    margin-bottom: 20px; }

@media print {
  .topbar, .footer, .sidebar-navigation {
    display: none;
    margin: 0;
    padding: 0; }

  .panel-body {
    padding: 40px 0 0 0; }

  #page-right-content {
    border: 0 !important;
    margin: 0;
    padding: 0;
    min-height: 1px; } }
/* =============
   Members list
============= */
.member-card .member-thumb {
  position: relative; }
.member-card .member-star {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  line-height: 26px;
  text-align: center;
  font-size: 18px; }

.user-badge {
  position: absolute;
  top: 15px;
  left: 0;
  padding: 5px 15px;
  border-radius: 20px;
  color: #ffffff;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold; }

.social-links li a {
  border-radius: 50%;
  color: rgba(122, 125, 132, 0.5);
  display: inline-block;
  height: 30px;
  line-height: 27px;
  border: 2px solid rgba(122, 125, 132, 0.5);
  text-align: center;
  width: 30px; }
  .social-links li a:hover {
    color: #7a7d84;
    border: 2px solid #7a7d84; }

.widget-inline {
  padding: 20px 0 !important; }
  .widget-inline .col-lg-3 {
    padding: 0; }
    .widget-inline .col-lg-3:last-of-type .widget-inline-box {
      border-right: 0; }
  .widget-inline .widget-inline-box {
    border-right: 1px solid #e3e8f1;
    padding: 20px; }
    .widget-inline .widget-inline-box i {
      font-size: 28px;
      padding-right: 5px; }
    .widget--users-count, .widget--subscribed-count, .widget--invoices-count, 
    .widget--active-subscription, .widget--canceled-subscription, 
    .widget--users-count-hun, .widget--invoices-count-hun, .widget--today-users-count-hun {
      font-size: 33px;
    }
    .swithy-holder-dashboard, .swithy-holder-users {
      text-align: right;
    }

.bg-facebook {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #ffffff; }

.bg-twitter {
  background-color: #00aced !important;
  border-color: #00aced !important;
  color: #ffffff; }

.social-feed-box h3 {
  font-size: 15px;
  font-weight: normal;
  line-height: 24px; }

.social-feed-slider {
  padding-bottom: 50px; }

.social-feed-slider .carousel-indicators {
  bottom: 0; }

.widget-box-two {
  margin: 30px -22px 2px -22px;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important; }

.pro-widget-img {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  padding: 70px 0;
  background-size: cover;
  background: url(../images/bg.jpg) center right no-repeat; }

/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0; }

.fc-view {
  margin-top: 30px; }

.none-border .modal-footer {
  border-top: none; }

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px; }
  .fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: "Hind", sans-serif;
    line-height: 30px;
    text-transform: uppercase; }

.fc-day-grid-event .fc-time {
  font-family: "Hind", sans-serif; }

.fc-day {
  background: #ffffff; }

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active,
.fc-toolbar button:focus, .fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0; }

.fc th.fc-widget-header {
  background: #f5f5f5;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase; }

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
  border-color: gainsboro; }

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #626773;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px; }

.fc-state-hover {
  background: #f5f5f5; }

.fc-state-highlight {
  background: #f0f0f0; }

.fc-state-down, .fc-state-active, .fc-state-disabled {
  background-color: #23b195 !important;
  color: #ffffff !important;
  text-shadow: none !important; }

.fc-cell-overlay {
  background: #f0f0f0; }

.fc-unthemed .fc-today {
  background: #ffffff; }

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center; }

.external-event {
  cursor: move;
  margin: 10px 0;
  color: #ffffff;
  padding: 6px 10px; }

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Hind", sans-serif; }
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Hind", sans-serif; }

/* =============
   Pricing
============= */
.pricing-column {
  position: relative;
  margin-bottom: 40px; }
  .pricing-column .inner-box {
    position: relative;
    padding: 0 0 50px;
    background-color: #f5f5f5; }
  .pricing-column .plan-header {
    position: relative;
    padding: 0 0 25px; }
  .pricing-column .plan-title {
    font-size: 16px;
    padding: 20px 20px 16px 20px;
    font-family: "Hind", sans-serif;
    margin: -2px -2px 25px -2px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #458bc4; }
  .pricing-column .plan-price {
    font-size: 48px;
    font-family: "Hind", sans-serif;
    margin-bottom: 10px;
    color: #626773; }
  .pricing-column .plan-duration {
    font-size: 13px;
    color: #7a7d84; }
  .pricing-column .plan-stats {
    position: relative;
    padding: 30px 20px 15px; }
    .pricing-column .plan-stats li {
      margin-bottom: 15px;
      line-height: 24px; }

.ribbon {
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  font-family: "Hind", sans-serif; }
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    background: #23b195;
    background: linear-gradient(#4fc55b 0%, #4fc55b 100%);
    position: absolute;
    top: 19px;
    letter-spacing: 1px;
    left: -21px; }
    .ribbon span:before {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #38a943;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #38a943; }
    .ribbon span:after {
      content: "";
      position: absolute;
      right: 0;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #38a943;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #38a943; }

/* ============
   PROFILE
   ============ */
.profile-header {
  padding: 20px 0; }

/* ============
   Account pages
   ============ */
.wrapper-page {
  margin: 7% auto;
  position: relative;
  max-width: 520px; }
  .wrapper-page .card-box {
    padding: 30px;
    border-radius: 9px;
    border-color: rgba(98, 103, 115, 0.3); }

.account-pages .account-content {
  padding: 30px; }

.account-pages .account-btn {
  position: absolute;
  left: 0;
  right: 0; }

.expired-title {
  font-weight: normal;
  line-height: 36px;
  margin-bottom: 40px; }

/* Dashboard */
.mails .mail-select {
  padding: 12px 20px;
  min-width: 134px; }

.mails .checkbox {
  margin-bottom: 0;
  margin-top: 0;
  vertical-align: middle;
  display: inline-block;
  height: 17px; }

/*# sourceMappingURL=style.css.map */
